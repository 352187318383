<template>
  <div class="finish">
    <!-- <img src="../assets/image/baicheng/finish2.png" alt="" /> -->
    <h1>恭喜您完成全部测评</h1>
    <el-button class="exit" type="primary" @click="toExit">退出</el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    toExit() {
      sessionStorage.clear();
      this.$router.replace('/login');
    }
  },
  created() {
    
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .finish {
    width: 100%;
    // height: 100%;
    margin: 0 auto;
    text-align: center;
    // background: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -60px;
    // padding-top: 150px;
    // position: relative;
    // padding-top: calc((100% - 500px) / 2);
    // img {
    //   width: 300px;
    //   height: 300px;
    // }
    h1 {
      font-weight: 550;
      font-size: 28px;
      margin-bottom: 50px;
    }
  }
}
@media screen and (min-width: 1900px) {
  .finish {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -80px;
    // padding-top: 200px;
    // position: relative;
    // top: calc((100% - 650px) / 2);
    h1 {
      font-weight: 550;
      font-size: 36px;
      margin-bottom: 65px;
    }
    // .img {
    //   width: 380px;
    //   height: 380px;
    // }
  }
}
</style>
